import React from 'react'
import HomeCategory from '../Components/Common/HomeCategory';
import HeroSection from '../Components/Products/HeroSection';
import OtherProductsSlider from '../Components/Products/OtherProductsSlider';
import ShippingSection from '../Components/Products/ShippingSection';
import ScrollTop from '../Components/Common/ScrollTop';
import { Shortvideo } from '../Components/Common/Shortvideo';
import HomeProductSlider from '../Components/Products/HomeProductSlider';
import HomeBanner from '../Components/Products/HomeBanner';
import NewArrivals from '../Components/Products/NewArrivals';
import FocusCategories from '../Components/Products/FocusCategories';
import FlashSale from '../Components/Products/FlashSale';
import ShopCategory from '../Components/Products/ShopCategory';
import DealDay from '../Components/Products/DealDay';
import ProductHomeCategory from '../Components/Products/ProductHomeCategory';
import ChatWidget from './ChatWidget';

const Home = () => {

    const proCat = [{
        title: 'Handmade Products',
        product: [{ id: '1', name: 'God Crafts', img: "/assets/images/p-1.png" },
        { id: '2', name: 'Oil Pastel Paintings', img: "/assets/images/p-2.png" },
        { id: '3', name: 'God Crafts', img: "/assets/images/p-3.png" },
        { id: '4', name: 'Arts', img: "/assets/images/p-4.png" }]
    },
    {
        title: 'Electronics',
        product: [
            { id: '1', name: 'Headphones', img: "/assets/images/E-1.png" },
            { id: '2', name: 'Laptops', img: "/assets/images/E-2.png" },
            { id: '3', name: 'Mobiles', img: "/assets/images/E-3.png" },
            { id: '4', name: 'TVs', img: "/assets/images/E-4.png" }
        ]
    },
    {
        title: 'Automotive',
        product: [
            { id: '1', name: 'Car Accessories', img: "/assets/images/A-1.png" },
            { id: '2', name: 'Oil & Greace', img: "/assets/images/A-4.png" },
            { id: '3', name: 'Tyres & Tube', img: "/assets/images/A-2.png" },
            { id: '4', name: 'Bike Accessories', img: "/assets/images/A-3.png" }
        ]
    }
    ]

    return (
        <div>
            <HomeCategory />
            <HeroSection />
            <HomeProductSlider />
            <ShippingSection />
            <Shortvideo />
            <HomeBanner />
            <OtherProductsSlider />
            <NewArrivals />
            <Shortvideo />
            <FocusCategories />
            <FlashSale />
            <ShopCategory />
            <DealDay />
            
            <Shortvideo />
            <ProductHomeCategory proCat={proCat} />

            {/* <CouponGet /> */}

            {/* <TopStories /> */}
            <ScrollTop />
            <ChatWidget />
        </div >
    )
}

export default Home;