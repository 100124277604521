import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import MobileSearchModel from "../Model/MobileSearchModel";
import SideCartMenu from "../Products/SideCartMenu";
import { CircleUserRound, Heart, Search, ShoppingBag, User, UserRound } from "lucide-react";
import ToastService from "../../helpers/toast-services";

import { fetchWishListProducts } from "../../middlewares/wishlist/wishlist";
import { useDispatch, useSelector } from "react-redux";
import { LOCALSTORAGE_CART_KEY, LOCALSTORAGE_WISHLIST_KEY } from "../../config/constants";
import LocalstorageService from "../../helpers/localstorage-services";
import { updateWishListCount } from "../../slices/wishlist.slice";
import { getDoodlesData } from "../../middlewares/doodle";
import { useFormik } from "formik";
import { clearSearch } from "../../slices/search.slice";
import { searchProducts, searchProductsOnSubmit } from "../../middlewares/products/search";
import { updateCartCount } from "../../slices/cart";
import { getCartData } from "../../middlewares/cart/cart";
import { Avatar } from 'primereact/avatar';


const Header = ({ hearderMobile, onClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();;
  const url = useLocation();
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showSideCart, setShowSideCart] = useState(false);
  const [isFocused, setIsFocused]=useState(true);
  const { searchMessage, searchError, searchLoading, search } = useSelector((state) => state.search)
  const { userAddress, primary, addAddressMessage, addAddressError, SuccessMessage, primaryAddressError, primaryAddressMessage } = useSelector((state) => state.address);


  const doodlesData = useSelector((state) => (state.doodle.doodles));
  const { wishListCount } = useSelector((state) => state.wishlist);
  const { cartCount, cartUpdateMessage } = useSelector(state => state.cart);
  const user = LocalstorageService.getLoggedInUserDetails();
  useEffect(() => {
    dispatch(getDoodlesData());
    const storedCart = LocalstorageService.getInLocalStorage(LOCALSTORAGE_CART_KEY) || [];
    dispatch(updateCartCount(storedCart?.length))
    const bodyFormData = new FormData();
    const delivery_charge = userAddress.find(address => address?.set_default === 1);
    bodyFormData.append("user_id", user?.id);
    bodyFormData.append("customer_pincode", delivery_charge?.postal_code || null);
    dispatch(getCartData(bodyFormData));
    dispatch(fetchWishListProducts(bodyFormData));
  }, [dispatch]);
  const handleCloseSearchModal = () => setShowSearchModal(false);

  const handleFetchWishList = () => {
    dispatch(fetchWishListProducts());
  };

  const getInitials = (name) => {
    const [firstName, lastName] = name.trim().split(" ");
    return `${firstName[0]}${lastName?.[0] || ""}`;
  };

  const handleSubmitSearch = (values) => {
    if (!values.search) {
      dispatch(clearSearch());
    } else {
      const payload = {
        search: values.search,
      }
      dispatch(searchProducts(payload));
    }
  }

  const searchFormik = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: handleSubmitSearch
  });

  const handleSearch = (values) => {
    if (Array.isArray(values.products)) {
      navigate(`${values.slug}/product-list`)
    } else {
      window.open(`${values.slug}/product-details/${values.products.id}`, '_blank');
    }
    searchFormik.resetForm();
    dispatch(clearSearch());
  }

  const handleLogout = () => {
    localStorage.removeItem('IN_USER_TOKEN');
    localStorage.removeItem('IN_USER_DATA');
    localStorage.removeItem('IN_CAT');
    localStorage.removeItem('IN_WISHLIST');
    localStorage.removeItem('IN_CART');
    dispatch(updateWishListCount())
    dispatch(updateCartCount())
    window.location.reload();
    ToastService.success('Logged Out Successfully');
    navigate('/')
  }
  const handleSearchSubmit=(e)=>{
    e.preventDefault();
    dispatch(clearSearch());
    const searchData=searchFormik.values.search;
    if(searchData.length>0){
      
      navigate(`product-list/${searchData}`);
    }
    
  }
  // const handleSearchTest=(e)=>{
  //   // e.preventDefault();
  //   console.log("hello");
  // }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <>
      <header className="header-section">
        <div className="container-fluid ">
          <div className="row align-items-center justify-content-between position-relative">
            <div className="col-lg-4 col-md-6 col-6 d-lg-block d-md-block position-relative">
              <div className="logo-wpr">
                <div className="logo-inner">
                  <Link
                    className="navbar-brand  d-md-block d-lg-block d-none "
                    to="/home"
                  >
                    <img src="/assets/images/svg-img/logo.svg" alt="Logo" />
                  </Link>
                  <Link
                    className="navbar-brand  d-md-none d-lg-none d-block"
                    to="/home"
                  >
                    <img src="/assets/images/svg-img/FevIcon.svg" alt="Logo" />
                  </Link>
                </div>
                <div className="border-logo d-md-block d-lg-block d-block"></div>
                <div className="festival-wpr d-lg-flex d-md-flex d-flex">

                  <div className="festival-img d-md-block d-lg-block d-none" >
                    <Link to='/blog-page'>
                      <img src={doodlesData[0]?.header_image} alt="header-img" className='header-img' />
                    </Link>
                  </div>
                  <div className="festival-name">
                    <Link to="/blog-page">
                      <img src={doodlesData[0]?.banner} alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-6 col-6 order-sm-2 order-md-3 order-lg-3" >
              <div className="row align-items-center justify-content-end">
                <div className="col-lg-7 col-md-7 d-lg-block d-md-none  d-none text-center" >
                <p className="Bharat-ki-apni-dukan">Bharat Ki Apni Dukaan</p>
                  <div className="searchForm position-relative">
                    
                    <form className="stop-propagation flex navForm" onSubmit={handleSearchSubmit} autocomplete="off">
                      <input
                        type="text"
                        name="search"
                        className="form-control searchInput"
                        placeholder="Search..."
                        value={searchFormik.values.search}
                        onChange={(e) => {
                          searchFormik.handleChange(e);
                          searchFormik.handleSubmit();
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      <button className="btn btn-success btnSearch"  >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </form>
                    <div className="search-bar-container results-list" >
                      
                     {/* {isFocused && ( */}
                      <ul >
                        {isFocused &&search?.length === 0 ? '' : search?.map((result, id) => {
                          const { products } = result;

                          const isProductObject = typeof products === 'object' && !Array.isArray(products);
                          const categoryName = isProductObject ? result.name : '';
                          const productName = isProductObject ? products.name : result.name;
                          const productImage = isProductObject ? products.photos : result.photos;

                          const duplicateCat = search.find((cat) => cat.id === result.id);

                          return (

                            <div key={id}>
                              {categoryName && isProductObject && (
                                // onClick={() => handleSearch(result)}
                                
                                <li className="d-flex align-items-center gap-3" style={{ cursor: 'pointer' }} onClick={() => handleSearch(result)}>
                                  <Search />
                                  
                    
                                </li>
                              
                              )}
                              <li className="d-flex align-items-center gap-3" style={{ cursor: 'pointer' }} onClick={() => handleSearch(result)}>
                                {!productImage ? <Search /> : <img src={productImage} alt={`img-${productImage}`} />}
                                <div>{productName}</div>
                              </li>
                            </div>
                          )
                        })}
                        
                      </ul>
                    {/* )} */}

                    </div>
                  </div>
                </div>
                <div className="col-xxl-5 col-xl-5 col-md-5 col-lg-4 col-sm-6">
                  <ul className="loginUser">
                    <li
                      className="nav-item flex d-md-block d-lg-none d-block"
                      title="search"
                    >
                      <Link to='/mobileSearchBar'>
                        <Search className="iconFont" />
                      </Link>
                    </li>

                    <li className="nav-item flex">
                      <div className="whislist-dropdown">
                        <Link
                          to="/wishlist"
                          className="nav-link position-relative"
                        >
                          <Heart
                            className="iconFont"
                            onClick={handleFetchWishList}
                          />
                          <span className="countNum">{wishListCount > 4 ? '4+' : wishListCount}</span>
                        </Link>
                      </div>
                    </li>

                    <li className="nav-item flex ">
                      <div className="nav-link position-relative"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowSideCart(!showSideCart)}
                      >
                        <ShoppingBag className="iconFont" />
                        <span className="countNumCart">{cartCount > 4 ? '4+' : cartCount || 0}</span>
                      </div>
                    </li>

                    {localStorage.getItem("IN_USER_TOKEN") ? (
                      <div className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle d-flex align-items-center"
                          href="#"
                          id="userDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <span className='d-lg-block d-md-block d-none'>
                            {/* <b> </b> */}
                            <Avatar label={getInitials(JSON.parse(localStorage.getItem('IN_USER_DATA')).name)} size="large" shape="circle" />
                          </span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="userDropdown">
                          <li>
                            <Link to="/profile" className="dropdown-item">
                              View Profile
                            </Link>
                          </li>
                          <li>
                            <a
                              style={{ cursor: "pointer" }}
                              className="dropdown-item"
                              onClick={handleLogout}>
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <li className="nav-item">
                        <div className="d-flex align-items-center gap-2">
                          <Link
                            to="/login"
                            className="nav-link d-flex align-items-center text-dark gap-1"
                          >
                            {/* <i className="fa-regular fa-user iconFont"></i> */}
                            <UserRound className="iconFont" />
                          </Link>
                        </div>
                      </li>
                    )}
                    {url.pathname !== '/' && (
                      <>
                        {hearderMobile && hearderMobile(onClick)}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileSearchModel
        showSearchModal={showSearchModal}
        handleCloseSearchModal={handleCloseSearchModal}
      />
      {showSideCart && <SideCartMenu
        showSideCart={showSideCart}
        handleCloseSideCart={() => setShowSideCart(!showSideCart)}
      />}
    </>
  );
};

export default Header;
