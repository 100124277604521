import React from 'react';
import { useTranslation } from 'react-i18next';
import { SELLER_ADD, SELLER_LOGIN } from '../../BaseUrl';

const TopBar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="top-bar-section">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-lg-2 col-md-2">
            <div className="top_header_inner list-inline mb-0 h-100 d-flex justify-content-start align-items-center">
            </div>
          </div> */}
          {/* <div className="col-lg-9 col-md-9 d-none d-lg-block d-md-block">
            <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center justify-content-end">
              <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
              <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
              <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
              <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
            </ul>
          </div> */}
          <div className="col-lg-12 col-md-12 text-right  d-flex  justify-content-between">
            <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center justify-content-end">
              <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
              <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
              <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
              <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
            </ul>
            <ul className="loginSeller list-inline mb-0 h-100 d-flex  align-items-center">
              {/* <!-- Become a Seller --> */}
              {/* <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
              <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
              <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
              <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li> */}
              <li className="top-btn">
                <a href={SELLER_ADD} target='_blank' rel="noopener noreferrer" className="tob-btn-a">
                  Become a Vendor !
                  {/* <p className="top-title"></p> */}
                  {/* <i className="fa-solid fa-handshake fa-beat" style={{ color: "#ffd563" }} />
                  <p className="top-description">Enjoy Benefits</p> */}
                </a>
              </li>
              <li className="top-btn">
                <a href={SELLER_LOGIN} target='_blank' rel="noopener noreferrer" className="tob-btn-a">
                  Login to Vendor
                  {/* <p className="top-title"></p> */}
                  {/* <i className="fa-solid fa-handshake fa-beat" style={{ color: "#ffd563" }} />
                  <p className="top-description top-description-2">Enjoy Benefits</p> */}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
