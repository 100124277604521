import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../Common/reel.css'
import { useDispatch, useSelector } from 'react-redux';
import { fetchShortVideo } from '../../middlewares/shortVideo';

const SingelSortVideo = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [res, setRes] = useState([]);
    const [videoStates, setVideoStates] = useState([]);
    const navigate =useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        dispatch(fetchShortVideo());
    }, [])

    const shortVideo = useSelector((state) => (state.shortvideo.shortVideo));

    const handleAddToCart=(video)=>{
        console.log(video);
    }

    const handleBuyNow=(video)=>{
        // console.log(video.category_slug);
        // console.log(video.id);
        navigate(`/${video.category_slug}/product-details/${video.id}`);
    }
 
    useEffect(() => {
        if (shortVideo && shortVideo.length > 0) {
            let updatedVideos = [...shortVideo];
    
            if (state && state.id) {
                const currentVideo = shortVideo.find(video => video.id === state.id);
                updatedVideos = updatedVideos.filter(video => video.id !== state.id);
                if (currentVideo) {
                    updatedVideos = [currentVideo, ...updatedVideos];
                }
            }
    
            setRes(updatedVideos);
            setVideoStates(updatedVideos.map(() => ({
                isPlaying: true,
                isLiked: false,
                isHidden: false,
                animateHeart: false,
                currentTime: 0,
                duration: 0,
            })));
        }
    }, [shortVideo, state]);
        
    const gap = 20;
    const handleTimeUpdate = (index) => {
        const videoElement = document.getElementById(`video-${index}`);
        setVideoStates(prevStates => {
            const newStates = [...prevStates];
            const currentVideoState = newStates[index];
            newStates[index] = {
                ...currentVideoState,
                currentTime: videoElement.currentTime,
                duration: videoElement.duration
            };
            return newStates;
        });
    };

    const handleScroll = (event) => {
        setCurrentIndex((prevIndex) => {
            if (event.deltaY > 0) {
                return Math.min(prevIndex + 1, res.length - 1); 
            } else if (event.deltaY < 0) {
                return Math.max(prevIndex - 1, 0);
            }
            return prevIndex;
        });
    };
    const togglePlayPause = () => {
        setVideoStates(prevStates => {
            const newStates = [...prevStates];
            const currentVideoState = newStates[currentIndex];
            const videoElement = document.getElementById(`video-${currentIndex}`);

            if (currentVideoState.isPlaying) {
                videoElement.pause();
            } else {
                videoElement.play();
            }

            newStates[currentIndex] = {
                ...currentVideoState,
                isPlaying: !currentVideoState.isPlaying,
            };
            return newStates;
        });
    };


    const toggleLikeBtn = () => {
        setVideoStates(prevStates => {
            const newStates = [...prevStates];
            const currentVideoState = newStates[currentIndex];
            newStates[currentIndex] = {
                ...currentVideoState,
                isLiked: !currentVideoState.isLiked,
                isHidden: true,
                animateHeart: true// Hide play/pause while animating heart
            };
            return newStates;
        });

        // Animation effect

        setTimeout(() => {

            setVideoStates(prevStates => {
                const newStates = [...prevStates];
                const currentVideoState = newStates[currentIndex];
                newStates[currentIndex] = {
                    ...currentVideoState,
                    isHidden: false,
                    animateHeart: false
                };
                return newStates;
            });
        }, 1500); // Match with the duration of the animation
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [currentIndex, res?.length]);


    if (res?.length === 0) {
        return <div>No videos available</div>;
    }

    return (
        <>
            <div className="reels-container">
                {res.map((video, index) => (
                    <div
                        className="reel"
                        key={video?.id}
                        style={{
                            transform: `translateY(${(index - currentIndex) * (860 + gap)}px)`,
                        }}
                    >
                        <div className="video-wrapper">
                            <video
                                id={`video-${index}`}
                                src={video?.video1}
                                controls={false}
                                autoPlay={videoStates[index]?.isPlaying}
                                loop
                                muted
                                className="reel-video"
                                onTimeUpdate={() => handleTimeUpdate(index)}
                            />
                            {videoStates[index]?.animateHeart && (
                                <div className="video-overlay animate">
                                    <i style={{ color: '#3f59a3' }} className={` ${videoStates[index].isLiked ? 'fa-solid' : 'fa-regular'} fa-heart`}></i>
                                </div>
                            )}

                            <div className={`play-pause-button ${videoStates[index].isHidden ? 'hidden' : ''}`} onClick={togglePlayPause}>
                                {videoStates[index]?.isPlaying ? <i className="fa-solid fa-pause"></i> : <i className="fa-solid fa-play reel-play-icon"></i>}
                            </div>
                            <div className="actions">
                                <div className='mb-2'>
                                    <button onClick={toggleLikeBtn}>
                                        {videoStates[index].isLiked ? <i style={{ color: '#3f59a3' }} className="fa-solid fa-heart"></i> : <i
                                            style={{ color: '#3f59a3' }}
                                            className="fa-regular fa-heart"></i>}
                                    </button>
                                    <p className='text-white likes-count'>122</p>
                                </div>
                                <button>
                                    <i className="fa-solid fa-share"></i>
                                </button>

                            </div>


                            <div className="progress-bar">
                                <div
                                    className="progress"
                                    style={{
                                        width: `${(videoStates[index].duration > 0 ? (videoStates[index].currentTime / videoStates[index].duration) * 100 : 0)}%`
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="uploader-info">
                            <div className='reel-uploader-info'>
                            {/* <img src={video?.photos[0]} alt={`${video?.category_slug}`} /> */}
                            <div className="uploader-details">
                                <h3>{video?.meta_title.slice(0,40)}</h3>
                                <p style={{fontWeight:"100",fontSize:"12px"}} >{video?.description?.slice(0,100)}</p>
                                <span className='reel-price'>₹{video?.indiazona_price}</span>
                                <span style={{fontWeight:"100",fontSize:"16px"}} > <s>₹{video?.mrp}</s></span>
                            </div>
                            </div>
                            <div className="button-reel-action">
                            <button className="subscribe-btn" onClick={(e)=>handleAddToCart(video)} style={{backgroundColor:"#f15b27"}}>Add to cart</button>
                            <button className="subscribe-btn" onClick={(e)=>handleBuyNow(video)} >Buy Now</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default SingelSortVideo